import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
/* Old Code For Limit Gapping

export function fetchList(query) {
  return request({
    url: '/get-limit-point-gapping',
    method: 'get',
    params: query
  })
} 
  
*/

export function fetchList(query) {
  return request({
    url: '/get-user-limit-point-gapping',
    method: 'get',
    params: query
  })
} 

export function update(data) {
  return request({
    url: '/limit-point-gapping/update',
    method: 'post',
    data
  })
}