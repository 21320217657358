<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Limit Point Gapping</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">

        <div class="search-wrapper market-watch-search">
          <!-- <el-input
              placeholder="Search"
              v-model="listQuery.search"
              class="input-with-select"
              clearable
              @clear="handleSearch()" 
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch()"
              ></el-button>
            </el-input> -->
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <!-- <el-button type="primary" @click="handleCreate()">Add</el-button>
            <el-button type="primary" @click="stockImport()" v-if = "has_import">Import Stock</el-button> -->
        </div>
      </el-col>
    </el-row>

    <div>
      <el-form :model="formData" ref="refForm" label-position="top" :status-icon="true">
        <el-tabs class="marketwatch_tab" type="card" @tab-click="handleTabClick" v-model="currentTab">
          <el-tab-pane label="NSE" name="nse">

            <el-row v-for="(script, index) in formData.nse" :key="index" style="padding-top: 5px;margin: 0 12px">
              <div v-if="script.name == 'default'">
                <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                  <label style="color:white;">{{ script.name_display }}</label>
                </el-col>
                <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
                  <el-input v-model="formData.nse[index].value" autocomplete="off" style="color: red;">
                    <template #append>%</template>
                  </el-input>

                </el-col>
              </div>
              <div v-else>
                <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                  <label style="color:white;">{{ script.name_display }}</label>
                </el-col>
                <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
                  <el-input v-model="formData.nse[index].value" autocomplete="off"></el-input>

                </el-col>
              </div>
            </el-row>
            <!-- <div style="padding-top: 60px">
            
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="MCX" name="mcx">

            <el-row v-for="(script, index) in formData.mcx" :key="index" style="padding-top: 5px;margin: 0 12px">
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <label style="color:white;">{{ script.name_display }}</label>
              </el-col>
              <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
                <el-input v-model="formData.mcx[index].value" autocomplete="off"></el-input>

              </el-col>
            </el-row>
          </el-tab-pane>

          <!-- <el-tab-pane label="OPT" name="opt">
          
          </el-tab-pane>
          <el-tab-pane label="CRYPTO" name="crypto" >
           
          </el-tab-pane>
          <el-tab-pane label="FOREX" name="forex" >
           
          </el-tab-pane> -->
        </el-tabs>
        <el-row style="padding-top: 5px; margin: 0 12px">
          <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
            <label style="color:white;">Select Client</label>
          </el-col>
          <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
            <el-form-item prop="user_id" required>
              <el-select v-model="formData.user_id" placeholder="Select" clearable filterable>
                <el-option v-for="item in listDataDropdownClients" :key="item.id" :label="item.username"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div style="text-align: center; margin-top: 20px;">
          <el-button type="primary" @click="handleStore()">Save</el-button>
        </div>
      </el-form>
    </div>
    <br />
    <br />
    <br />
    <div class="table-container limit-point-gapping-table">
      <table>
        <thead>
          <tr>
            <th rowspan="2">Username</th>
            <th colspan="3">MCX</th>
            <th colspan="4">NSE</th>
          </tr>
          <tr>
            <th>GOLD</th>
            <th>SILVER</th>
            <th>Default</th>
            <th>BANKNIFTY</th>
            <th>NIFTY</th>
            <th>FINNIFTY</th>
            <th>Default</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in userConfigTableData.data.item" :key="user.username">
            <td>{{ user.username }}</td>
            <td>{{ getValue(user.mcx, 'GOLD') }}</td>
            <td>{{ getValue(user.mcx, 'SILVER') }}</td>
            <td>{{ getValue(user.mcx, 'default') }}</td>
            <td>{{ getValue(user.nse, 'BANKNIFTY') }}</td>
            <td>{{ getValue(user.nse, 'NIFTY') }}</td>
            <td>{{ getValue(user.nse, 'FINNIFTY') }}</td>
            <td>{{ getValue(user.nse, 'default') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
//import Table from "@/components/Table/Table.vue";
import { fetchList, update } from "@/api/super-admin/limit-point-gapping";
// import { update } from "@/api/super-admin/limit-point-gapping"
//import getRoleSlug from "@/store/app.js";
import { fetchClientList } from "@/api/market-watch";
import { mapGetters } from "vuex";
//import { _ } from "vue-underscore";



export default {
  name: "LimitPointGapping",
  components: {
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibleEdit: false,
      userConfigTableData: {
        data: {
          item: [],
        },
      },
      loading: false,
      tableLockActionVisibility: true,
      tableCryptoVisibility: true,
      tableActionLogoutVisibility: true,
      tableForexVisibility: true,
      addData: {
        exchange: "NSE"
      },
      listDataDropdownClients: [],
      listDataNSE: {
        data: {
          item: [],
        },
      },
      listDataOPT: {
        data: {
          item: [],
        },
      },
      listDataMCX: {
        data: {
          item: [],
        },
      },
      listDataCRYPTO: {
        data: {
          item: [],
        },
      },
      listDataFOREX: {
        data: {
          item: [],
        },
      },
      tablePagination: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      formData: {
        user_id: null,
        nse: [],
        mcx: [],
      },
      formErrors: [],
      dialogType: "",
      dialogTypeEdit: "edit",
      search: "",
      currentTab: "nse",
      type: "nse",
      has_import: true,
      isConnected: false,
      socketMessage: ''
    };
  },
  sockets: {
    connect: function () {
      console.log('connected live data')
    },

  },
  computed: {

    ...mapGetters(["getRoleSlug"]),
  },

  created() {
    // this.getList();
    this.getTableList();
    this.getClientList();
    this.setDefaultData();
  },

  methods: {
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      // this.getList();
    },

    getValue(data, name) {
      const item = data.find(entry => entry.name === name);
      return item ? item.value : 'N/A'; // Return value or 'N/A' if not found
    },

    handleEdit(id) {
      this.formData.user_id = id;
    },

    handleTabClick(tab) {
      if (tab.index == 0) {
        this.type = 'nse'
      } else if (tab.index == 1) {
        this.type = 'mcx'
      } else if (tab.index == 2) {
        this.type = 'opt'
      } else if (tab.index == 3) {
        this.type = 'crypto'
      } else if (tab.index == 4) {
        this.type = 'forex'
      }
      // this.getList();
    },


    /* ODl block Code For Master Limite Point Gapping
    getList() {
      this.loading = true;
      // this.listQuery.exchange = this.type ?? 'nse';
      fetchList(this.listQuery).then((response) => {
        this.formData = response.data.data;
        this.formData.user_id
        this.loading = false;
      });
    },
    end Od Code */


    getTableList() {
      this.loading = true;
      // this.listQuery.exchange = this.type ?? 'nse';
      fetchList().then((response) => {
        this.userConfigTableData.data.item = response.data.data
        this.loading = false;

      });
    },

    getClientList() {
      this.loading = true;
      fetchClientList().then((response) => {
        this.listDataDropdownClients = response.data.data;
      });
      this.loading = false
    },
    /* New Code For Set Default Values */

    setDefaultData() {
      // Manually set the NSE and MCX data
      this.formData.nse = [
        { name: "BANKNIFTY", name_display: "BANKNIFTY", value: "0", type: "NSE" },
        { name: "NIFTY", name_display: "NIFTY", value: "0", type: "NSE" },
        { name: "FINNIFTY", name_display: "FINNIFTY", value: "0", type: "NSE" },
        { name: "default", name_display: "Default", value: "0", type: "NSE" }
      ];
      this.formData.mcx = [
        { name: "GOLD", name_display: "GOLD", value: "0", type: "MCX" },
        { name: "SILVER", name_display: "SILVER", value: "0", type: "MCX" },
        { name: "default", name_display: "Default", value: "0", type: "MCX" }
      ];
    },

    /* nd Of New Code*/

    handleCreate() {
      this.dialogType = 'create';
      this.dialogVisible = true;
      this.addData = {
        exchange: 'NSE'
      }
    },

    handleStore() {
      if (this.formData.user_id) {
        this.loading = true;
        this.dialogVisible = false;
        let data = this.formData;
        update(data).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getTableList();
          } else {
            this.flashError(response.data.message);
          }
        });
      } else {
        this.flashError("username Is Required");
      }
    },
  },
};
</script>
<style>
.limit-point-gapping-table {
  padding: 20px;
  overflow-x: auto;
}

.limit-point-gapping-table table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.limit-point-gapping-table th,
.limit-point-gapping-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.limit-point-gapping-table th {
  background-color: #f8f8f8;
  font-weight: bold;
}

.limit-point-gapping-table thead th[rowspan="2"] {
  vertical-align: middle;
}

.limit-point-gapping-table td {
  background-color: #fefefe;
}

.limit-point-gapping-table tr:nth-child(even) td {
  background-color: #f5f5f5;
}

.limit-point-gapping-table table {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.limit-point-gapping-table thead th[colspan] {
  background-color: #e0e0e0;
}

.limit-point-gapping-table tbody td {
  font-size: 14px;
}
</style>